import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tag", "joinedTagsDisplay" ]

  connect() {
    this.sync()
  }

  changeTagsManually(event) {
    this.sync()
  }

  selectTag(event) {
    let selectedTagName = event.target.textContent

    if (event.target.classList.contains("selected")) {
      this.joinedTagsDisplayTarget.value = this.joinedTagsDisplayTarget.value.replace(selectedTagName, "")

      // Remove double commas
      this.joinedTagsDisplayTarget.value = this.joinedTagsDisplayTarget.value.replace(/( *, *)+/, ", ")

      // Remove leading or trailing commas
      this.joinedTagsDisplayTarget.value = this.joinedTagsDisplayTarget.value.replace(/^ *, */, "")
      this.joinedTagsDisplayTarget.value = this.joinedTagsDisplayTarget.value.replace(/ *, *$/, "")

      event.currentTarget.classList.remove("selected")
    }
    else {
      this.joinedTagsDisplayTarget.value = this.joinedTagsDisplayTarget.value + ", " + selectedTagName
      event.currentTarget.classList.add("selected")
    }
  }

  // ===========================================================================
  // Private
  // ===========================================================================

  sync() {
    let tagNames = this.joinedTagsDisplayTarget.value.split(",")

    // Unselect all tags
    this.tagTargets.forEach(x => x.classList.remove("selected"))

    // Select all tags whose name is in the text field
    let trimmedTagNames = tagNames.map(x => x.trim())
    let z = this.tagTargets
                .filter(x => trimmedTagNames.includes(x.innerText))
                .forEach(x => x.classList.add("selected"))
  }
}
