import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    if (this.data.has("show") && this.data.get("show").match(/^(true|yes|1)$/)) {
      this._unwrap(this.element)
    }

    this.element.remove()
  }

  // ===========================================================================
  // Private
  // ===========================================================================

  _unwrap(element) {
    element.outerHTML = element.innerHTML
  }
}
