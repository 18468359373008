import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "bookOnlyEditionSelector",
    "completeEditionSelector",
    "discountCodeField",
    "startPaymentButton"
  ]

  selectEdition(event) {
    this.bookOnlyEditionSelectorTarget.classList.remove("selected")
    this.completeEditionSelectorTarget.classList.remove("selected")
    event.currentTarget.classList.add("selected")

    if (this.bookOnlyEditionSelectorTarget.classList.contains("selected"))
      this.data.set("edition", "book-only")
    else
      this.data.set("edition", "complete")
  }

  startPayment(event) {
    this.startPaymentButtonTarget.textContent = "Redirecting to checkout …"
    this.startPaymentButtonTarget.disabled = true

    var stripe = Stripe(this.data.get("publishableApiKey"), { locale: "en" })

    fetch("/checkout_sessions", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").getAttribute("content")
      },
      body: JSON.stringify(
        {
          product: this.data.get("product"),
          edition: this.data.get("edition"),
          discount_code: this.discountCodeFieldTarget.value
        }
      )
    })
    .then(function(response) {
      console.log(response);
      return response.json();
    })
    .then(function(session) {
      console.log(session);
      return stripe.redirectToCheckout({ sessionId: session.id });
    })
    .then(function(result) {
      // If redirectToCheckout fails due to a browser or network error, we
      // display the localized error message.
      if (result.error) {
        alert(result.error.message);
      }
    })
    .catch(function(error) {
      console.error("Error:", error);
    });
  }
}
